<template>
  <b-col class="list_post-col" lg="3" md="5" sm="6" xs="12">
    <div class="header">
      <div class="list flex justify-content-center">
        <span class="list-title">{{ list.title }}</span>
      </div>

      <div class="addPost">
        <b-button class="list-btn" block v-b-modal="`addPost_${list.id}`"
          >+</b-button
        >
        <b-modal
          :ref="`addPostModal_${list.id}`"
          :id="`addPost_${list.id}`"
          centered
          hide-footer
          :title="$t('g.addNewPost')"
        >
          <validation-observer :ref="`addPostForm_${list.id}`">
            <overlay-component :isLoading="is_loading" />
            <b-form @submit.stop.prevent="addPost">
              <b-form-group label-for="title" class="control-input">
                <validation-provider
                  name="title"
                  rules="required"
                  #default="{ errors }"
                >
                  <b-form-input
                    v-model="form_data.title"
                    :state="errors.length > 0 ? false : null"
                    name="title"
                    :placeholder="$t('g.title')"
                  ></b-form-input>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label-for="text" class="control-input">
                <validation-provider
                  name="text"
                  rules="required"
                  #default="{ errors }"
                >
                  <b-form-textarea
                    v-model="form_data.text"
                    :state="errors.length > 0 ? false : null"
                    name="text"
                    :placeholder="$t('g.text')"
                  ></b-form-textarea>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form File Input -->
              <b-form-group label-for="file" class="control-input">
                <validation-provider name="file" #default="{ errors }">
                  <b-form-file
                    v-model="form_data.media"
                    :state="errors.length > 0 ? false : null"
                    name="media"
                    :browse-text="$t('g.browse')"
                    :placeholder="$t('g.choosePostFile')"
                  ></b-form-file>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Start Form Actions Buttons -->
              <b-row align-h="end" class="report-footer m-0 mt-5">
                <b-button
                  class="mr-1"
                  variant="outline-primary"
                  @click="$bvModal.hide('addList')"
                >
                  {{ $t("g.cancel") }}
                </b-button>
                <b-button
                  :disabled="is_loading"
                  variant="primary"
                  type="submit"
                  @click.prevent="addPost"
                >
                  {{ $t("g.Send") }}
                </b-button>
              </b-row>
              <!-- Start Form Actions Buttons -->
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
    </div>

    <div class="list-post">
      <div class="post" v-for="post in posts" :key="post.id">
        <div class="header">
          <span
            class="title p-0 m-0 cursor-pointer"
            @click="showPost(post.id)"
            >{{ userFullName(post) }}</span
          >
          <b-dropdown
            v-if="
              $store.state.userData &&
              post.user.id !== $store.state.userData.userId
            "
            class="dropdwon"
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
          >
            <template #button-content>
              <img
                class="cursor-pointer"
                src="@/assets/images/icons/three-dots.png"
                alt="Three Dots"
              />
            </template>
            <b-dropdown-item
              class="dropdown-details"
              variant="link"
              @click="addReport(post.id)"
            >
              <span>{{ $t("g.addReport") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="post-content cursor-pointer" @click="showPost(post.id)">
          <h6 class="d-flex justify-content-between">
            <span>{{ post.title }}</span>
          </h6>
          <img
            v-if="post.media && post.media.length > 0"
            :src="post.media[0].path"
            :alt="post.title"
            class="img-fluid h-100 w-100 bg-cover rounded-lg"
          />
          <span v-else>{{ post.text }}</span>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BRow,
} from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BFormTextarea } from "bootstrap-vue/src/components/form-textarea";
import { required } from "@core/utils/validations/validations";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ListPosts",
  components: {
    BDropdownItem,
    BDropdown,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BButton,
    BForm,
    BRow,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    OverlayComponent,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      is_loading: false,
      data: {
        board_id: "",
        list_id: "",
        post_id: "",
      },
      form_data: {
        title: "",
        text: "",
        media: null,
      },
      posts: [],
      okTitleHtml: "",
      report: {
        show: false,
        reportable_id: null,
        reportable_type: null,
      },
    };
  },
  created() {
    this.data.board_id = this.$route.params.id;
    this.data.list_id = this.list.id;
    this.getPots();
  },
  methods: {
    getPots() {
      this.$http
        .get(
          `/teacher/boards/${this.$route.params.id}/lists/${this.list.id}/posts`
        )
        .then((response) => {
          this.posts = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addPost() {
      this.$refs[`addPostForm_${this.list.id}`].validate().then((success) => {
        if (success) {
          const formData = new FormData();
          formData.append("title", this.form_data.title);
          formData.append("text", this.form_data.text);
          if (this.form_data.media)
            formData.append("media", this.form_data.media);
          this.okTitleHtml =
            '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
          this.is_loading = true;

          this.$http
            .post(
              `/teacher/boards/${this.$route.params.id}/lists/${this.list.id}/posts`,
              formData
            )
            .then((res) => {
              if ([200, 201].includes(res.status)) {
                this.$refs[`addPostModal_${this.list.id}`].hide();
                this.getPots();
                this.form_data.title = "";
                this.form_data.text = "";
                this.okTitleHtml = "";
                this.is_loading = false;
                toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    text: this.$t("g.post_added_successfully"),
                  },
                });
              }
            })
            .catch((error) => {
              this.is_loading = false;
              toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: this.$t("g.something_went_wrong"),
                },
              });
            });
        }
      });
    },
    userFullName(post) {
      let fullName = "";
      if (post.user) {
        if (post.user.first_name) {
          fullName += post.user.first_name;
        }
        if (post.user.middle_name) {
          fullName += ` ${post.user.middle_name}`;
        }
        if (post.user.last_name) {
          fullName += ` ${post.user.last_name}`;
        }
      } else {
        fullName = post.title;
      }
      return fullName;
    },
    showPost(postId) {
      this.$router.push({
        name: "showPost",
        params: {
          boardId: this.$route.params.id,
          listId: this.list.id,
          postId: postId,
        },
      });
    },
    addReport(id) {
      this.report.reportable_id = id;
      this.report.reportable_type = "post";
      this.$emit("showReport", this.report);
    },
  },
};
</script>

<style lang="scss" src="./_listPosts.scss" />

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"list_post-col",attrs:{"lg":"3","md":"5","sm":"6","xs":"12"}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"list flex justify-content-center"},[_c('span',{staticClass:"list-title"},[_vm._v(_vm._s(_vm.list.title))])]),_c('div',{staticClass:"addPost"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("addPost_" + (_vm.list.id))),expression:"`addPost_${list.id}`"}],staticClass:"list-btn",attrs:{"block":""}},[_vm._v("+")]),_c('b-modal',{ref:("addPostModal_" + (_vm.list.id)),attrs:{"id":("addPost_" + (_vm.list.id)),"centered":"","hide-footer":"","title":_vm.$t('g.addNewPost')}},[_c('validation-observer',{ref:("addPostForm_" + (_vm.list.id))},[_c('overlay-component',{attrs:{"isLoading":_vm.is_loading}}),_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addPost.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"control-input",attrs:{"label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"name":"title","placeholder":_vm.$t('g.title')},model:{value:(_vm.form_data.title),callback:function ($$v) {_vm.$set(_vm.form_data, "title", $$v)},expression:"form_data.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"control-input",attrs:{"label-for":"text"}},[_c('validation-provider',{attrs:{"name":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"name":"text","placeholder":_vm.$t('g.text')},model:{value:(_vm.form_data.text),callback:function ($$v) {_vm.$set(_vm.form_data, "text", $$v)},expression:"form_data.text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"control-input",attrs:{"label-for":"file"}},[_c('validation-provider',{attrs:{"name":"file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"name":"media","browse-text":_vm.$t('g.browse'),"placeholder":_vm.$t('g.choosePostFile')},model:{value:(_vm.form_data.media),callback:function ($$v) {_vm.$set(_vm.form_data, "media", $$v)},expression:"form_data.media"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-row',{staticClass:"report-footer m-0 mt-5",attrs:{"align-h":"end"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$bvModal.hide('addList')}}},[_vm._v(" "+_vm._s(_vm.$t("g.cancel"))+" ")]),_c('b-button',{attrs:{"disabled":_vm.is_loading,"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addPost.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("g.Send"))+" ")])],1)],1)],1)],1)],1)]),_c('div',{staticClass:"list-post"},_vm._l((_vm.posts),function(post){return _c('div',{key:post.id,staticClass:"post"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"title p-0 m-0 cursor-pointer",on:{"click":function($event){return _vm.showPost(post.id)}}},[_vm._v(_vm._s(_vm.userFullName(post)))]),(
            _vm.$store.state.userData &&
            post.user.id !== _vm.$store.state.userData.userId
          )?_c('b-dropdown',{staticClass:"dropdwon",attrs:{"variant":"link","toggle-class":"text-decoration-none p-0","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/icons/three-dots.png"),"alt":"Three Dots"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{staticClass:"dropdown-details",attrs:{"variant":"link"},on:{"click":function($event){return _vm.addReport(post.id)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("g.addReport")))])])],1):_vm._e()],1),_c('div',{staticClass:"post-content cursor-pointer",on:{"click":function($event){return _vm.showPost(post.id)}}},[_c('h6',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(post.title))])]),(post.media && post.media.length > 0)?_c('img',{staticClass:"img-fluid h-100 w-100 bg-cover rounded-lg",attrs:{"src":post.media[0].path,"alt":post.title}}):_c('span',[_vm._v(_vm._s(post.text))])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }
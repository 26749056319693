<template>
  <b-col class="list_list-col" lg="3" md="5" sm="6" xs="12">
    <div class="header">
      <div
        class="list flex justify-content-center cursor-pointer"
        @click="$bvModal.show(`addList`)"
      >
        <b-button block class="list-btn">+</b-button>
        <b-modal
          ref="addListModal"
          id="addList"
          centered
          hide-footer
          :title="$t('g.show_board/add_list/addNewList')"
        >
          <validation-observer ref="addListForm">
            <b-form>
              <!-- Form Title Input -->
              <b-form-group label-for="title" class="control-input">
                <validation-provider
                  name="title"
                  rules="required"
                  #default="{ errors }"
                >
                  <b-form-input
                    v-model="form_data.title"
                    :state="errors.length > 0 ? false : null"
                    name="title"
                    :placeholder="$t('g.classroom.show_course/boards/title')"
                  ></b-form-input>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Description Input -->
              <b-form-group label-for="text" class="control-input">
                <validation-provider name="text" #default="{ errors }">
                  <b-form-textarea
                    v-model="form_data.description"
                    :state="errors.length > 0 ? false : null"
                    name="description"
                    :placeholder="
                      $t('g.classroom.show_course/boards/description')
                    "
                  ></b-form-textarea>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Is Allow Posting -->
              <b-form-group label-for="text" class="control-input">
                <validation-provider
                  name="text"
                  rules="required"
                  #default="{ errors }"
                >
                  <b-form-checkbox
                    v-model="form_data.is_allow_posting"
                    :state="errors.length > 0 ? false : null"
                    name="is_allow_posting"
                    :placeholder="$t('g.show_board/add_list/is_allow_posting')"
                    class="list-check-box"
                  >
                    {{ $t("g.show_board/add_list/is_allow_posting") }}
                  </b-form-checkbox>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Start Form Actions Buttons -->
              <b-row align-h="end" class="report-footer m-0 mt-5">
                <b-button
                  class="mr-1"
                  variant="outline-primary"
                  @click="$bvModal.hide('addList')"
                >
                  {{ $t("g.cancel") }}
                </b-button>
                <b-button
                  :disabled="overlayLoading"
                  variant="primary"
                  type="submit"
                  @click.prevent="createList"
                >
                  {{ $t("g.Send") }}
                </b-button>
              </b-row>
              <!-- Start Form Actions Buttons -->
            </b-form>
          </validation-observer>
          <overlay-component :isLoading="overlayLoading" />
        </b-modal>
      </div>
    </div>
  </b-col>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
} from "bootstrap-vue";
import { required } from "@core/utils/validations/validations";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BFormTextarea } from "bootstrap-vue/src/components/form-textarea";

export default {
  name: "AddList",
  components: {
    OverlayComponent,
    ValidationProvider,
    ValidationObserver,
    required,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BForm,
    BCol,
    BModal,
    BFormCheckbox,
    BRow,
  },
  data() {
    return {
      form_data: {
        title: null,
        description: null,
        is_allow_posting: 0,
      },
      overlayLoading: false,
      okTitleHtml: "",
    };
  },
  methods: {
    createList() {
      this.$refs.addListForm
        .validate()
        .then((success) => {
          if (success) {
            this.overlayLoading = true;
            let formData = new FormData();
            formData.append("title", this.form_data.title);
            this.form_data.description &&
              formData.append("description", this.form_data.description);
            formData.append(
              "is_allow_posting",
              this.form_data.is_allow_posting ? 1 : 0
            );

            this.$http
              .post(`/teacher/boards/${this.$route.params.id}/lists`, formData)
              .then((response) => {
                this.okTitleHtml = "";
                this.form_data.title = "";
                this.form_data.description = "";
                this.form_data.is_allow_posting = 0;
                this.$emit("createList");
                this.overlayLoading = false;
                this.$bvModal.hide("addList");
                this.$helpers.makeToast(
                  "success",
                  this.$t("g.send.successTitle"),
                  this.$t("g.show_board/add_list/board_list_added_successfully")
                );
              })
              .catch((error) => {
                this.overlayLoading = false;
                this.$helpers.handleError(error);
              });
            this.$emit("createList");
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
